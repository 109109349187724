import api from '../api/campaigns'
import calc from '../utils/calc'

// const headers = {
//   campaign_id: 'Идентификатор',
//   campaign_name: 'Имя',
//   clicks: 'Клики',
//   cost: 'Расходы',
//   impressions: 'Показы',
//   bounces: 'Отказы',
//   report_date: 'Дата',
//   device: 'Устройство',
//   avg_impression_position: 'Cредняя позиция показа',
//   avg_click_position: 'Cредняя позиция клика',
//   avg_traffic_volume: 'Cредний объем трафика',
// }

const initialState = {
  campaigns: [],
  header: [],
  fromDate: null,
  toDate: null,
  isLoading: true,
  showColumnsDefault: [],
  showColumns: [],
  total: {},
}

const getters = {
  campaigns: state => state.campaigns,
  header: state => state.header,
  isLoading: state => state.isLoading,
  fromDate: state => state.fromDate ||
    ((new Date().getMonth() === 0 ) ? (new Date().getFullYear() -1) : new Date().getFullYear()) + "-" +
    ((new Date().getMonth() !== 0 && new Date().getMonth()<10) ? '0' : '')+
    ((new Date().getMonth() === 0 ) ? 12 : new Date().getMonth())+ "-"+
    ((new Date().getDate()<10) ? '0' : '')+
    ((new Date().getDate() > 27) ? "28" : new Date().getDate()),
  toDate: state => state.toDate || new Date().toISOString().split('T')[0],
  showColumnsDefault: state => state.showColumnsDefault,
  headerText: state => state.headerText,
  showColumns: state => state.showColumns,
  total: state => state.total,
  showHeader: state => {return state.header.filter((col) => state.showColumns.includes(col.value)).concat([{ text: 'Подробнее', value: 'more', sortable: false }])},
}

const actions = {
  prefetchCampaigns({ commit, getters, rootGetters }) {
    return api.campaigns(rootGetters['user/activeAccountLogin'], getters.fromDate, getters.toDate)
      .then(response => {
        const {header, shown_by_default, header_text, campaigns} = response['data']
        // let items = []
        // campaigns.forEach(item => {
        //   let row = {}
        //   header.forEach((col, index) => row[col] = item[index])
        //   items.push(row)
        // })
        let header_obj = []
        header.forEach(col => header_obj.push({
          text: header_text[col] || col,
          value: col,
        }))
        let shown_by_default_obj = []
        shown_by_default.forEach(col => shown_by_default_obj.push({
          text: header_text[col] || col,
          value: col,
        }))
        commit('setCampaignHeader', header_obj)
        commit('setCampaigns', campaigns)
        commit('setShowColumnsDefault', shown_by_default_obj)
        if (getters.showColumns.length < 1)
          commit('setShowColumns', shown_by_default_obj.map((item)=>item.value))
        commit('isLoading', false)
        commit('setTotal', calc.calc_total(campaigns))
      })
      .catch(e => {
        commit('isLoading', false)
        console.log(e)
      })
  },
}

const mutations = {
  setFromDate (state, val) {
    state.fromDate = val
  },
  setToDate (state, val) {
    state.toDate = val
  },
  setTotal (state, val) {
    state.total = val
  },
  isLoading (state, val) {
    state.isLoading = val
  },
  setCampaignHeader (state, val) {
    state.header = val
  },
  setCampaigns (state, val) {
    state.campaigns = val
  },
  setShowColumnsDefault (state, val) {
    state.showColumnsDefault = val
  },
  setShowColumns (state, val) {
    state.showColumns = val
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
